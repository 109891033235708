<template>
  <videos-list></videos-list>
</template>

<script>
import VideosList from './VideosList.vue';

export default {
  components: {
    VideosList,
  },
};
</script>
